import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import FAQ from "../component/Faq";

import { faHandsHelping, faUsers, faWalking } from '@fortawesome/free-solid-svg-icons';

const About = () => {
  return (
  <>
  <section className='about-main space'>
       <div className="container-xxl py-5">
         <div className="container">
           <div className="row g-5 align-items-center">
             <div className="col-lg-6">
               <h6 className="section-title text-start text-primary text-uppercase">About Us</h6>
               <h3 className="mb-4">
                 Welcome to <span className="text-primary text-uppercase">BORNFREETREKS</span>
               </h3>
               <p className="mb-4">
               At Born Free Treks, we know how important it is to address common queries and concerns before you plan your trekking adventure. Here are answers to some of the most frequently Googled questions about trekking:
               </p>
               <div className="row g-3 pb-4">
               {[
             { icon: faWalking, label: "Treks",list:"50", },
             { icon: faHandsHelping, label: "Support Team",list:"80", },
             { icon: faUsers, label: "Clients",list:"350", },
           ].map((item, index) => (
             <div key={index} className="col-sm-4 wow fadeIn" data-wow-delay={`${0.1 + index * 0.2}s`}>
               <div className="border rounded p-1">
                 <div className="border rounded text-center p-4">
                   <FontAwesomeIcon icon={item.icon} className="fa-2x text-primary mb-2" />
                   <h2 className="mb-1" data-toggle="counter-up">{item.list}</h2>
                   <p className="mb-0">{item.label}</p>
                 </div>
               </div>
             </div>
           ))}
               </div>
               <a className="btn btn-primary py-3 px-5 mt-2 text-dark rounded-2 fw-bold" href="#">Explore More</a>
             </div>
             <div className="col-lg-6">
               <div className="row g-3">
                 {[
                   { src: require("../assets/images/gallery/im4.JPG"), width: "75%", align: "text-end", marginTop: "25%" },
                   { src: require("../assets/images/gallery/im5.JPG"), width: "90%", align: "text-start",marginBottom:"5%" },
                   { src: require("../assets/images/gallery/im9.JPG"), width: "50%", align: "text-end" },
                   { src: require("../assets/images/gallery/im8.JPG"), width: "75%", align: "text-start",marginTop:"5%" },
                 ].map((img, index) => (
                   <div key={index} className={`col-6 ${img.align}`} style={{ marginTop: img.marginTop || 0 }}>
                     <img
                       className="img-fluid rounded wow zoomIn"
                       data-wow-delay={`${0.1 + index * 0.2}s`}
                       src={img.src}
                       style={{ width: img.width }}
                       alt="About Us"
                     />
                   </div>
                 ))}
               </div>
             </div>
           </div>
         </div>
       </div>
       </section>
  </>

    
  );
};

export default About;
