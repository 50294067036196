import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Mainslider = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="custom-slider-container">
      <Slider {...settings}>
        <div className="custom-slider-item">
          <img
           src={require('../assets/images/slide1.jpg')}
            alt="Trekking"
            className="custom-slider-image"
          />
          <div className="custom-slider-overlay"></div> {/* Semi-transparent overlay */}
          <div className="custom-slider-content">
            <h1>How Trekking with Born Free Treks is Transformative</h1>
            <button className="custom-slider-button">Get in Touch</button>
            <p>
              Join 30,000+ trekkers every year on mindfully designed experiences
              that help you connect to yourself, to nature and to others.
            </p>
            <a href="/" className="custom-slider-link">
              Read 9,200+ Google reviews with an average of 4.8 rating.
            </a>
          </div>
        </div>
        <div className="custom-slider-item">
          <img
           src={require('../assets/images/slide1.jpg')}
            alt="Trekking"
            className="custom-slider-image"
          />
          <div className="custom-slider-overlay"></div> {/* Semi-transparent overlay */}
          <div className="custom-slider-content">
            <h1>How Trekking with Born Free Treks is Transformative</h1>
            <button className="custom-slider-button">Get in Touch</button>
            <p>
              Join 30,000+ trekkers every year on mindfully designed experiences
              that help you connect to yourself, to nature and to others.
            </p>
            <a href="/" className="custom-slider-link">
              Read 9,200+ Google reviews with an average of 4.8 rating.
            </a>
          </div>
        </div>



     
      </Slider>
    </div>
  );
};

export default Mainslider;
