import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong } from "@fortawesome/free-solid-svg-icons/faRightLong";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";


const trekData = [
  // {
  //   title: "Benog Wildlife Sanctuary",
  //   duration: "2 Days",
  //   difficulty: "Difficult",
  //   description: "A scenic trek through lush forests, home to rare bird species and leopards. Offers panoramic views of the Himalayan peaks.",
  //   image: require("../assets/images/Benog_Wildlife_Sanctuary_.webp"),
  // },
 {
  id:"1",
  title: "Benog Wildlife Sanctuary",
  
  subtitle: "Serene Haven for Birds and Rare Wildlife",
  duration: "2 Days",
  difficulty: "Difficult",
  description: "A scenic trek through lush forests, home to rare bird species and leopards. Offers panoramic views of the Himalayan peaks.",
  maindescription: "Benog Wildlife Sanctuary is a thrilling trek through dense pine and oak forests, offering a perfect blend of adventure and nature. The trail is home to diverse wildlife, including rare birds, leopards, and Himalayan goats, making it a paradise for wildlife enthusiasts. The trek rewards visitors with breathtaking views of the snow-capped Himalayan peaks and serene landscapes. Camping under the star-lit sky adds to the experience, making it an unforgettable getaway. Ideal for seasoned trekkers, this journey is both challenging and rewarding.",
  highestAltitude: "8,500 ft",
  mainbanner:require("../assets/images/bannertrek/benong.jpg"),
  totalDistance: "15 km",
  accommodation: "Tents",
  basecamp: "Mussoorie",
  pickupPoint: "Dehradun Railway Station",
  trekFee: "₹5,500",
  image: require("../assets/images/Benog_Wildlife_Sanctuary_.webp"),
  gallery: [
    require("../assets/images/gallery/im1.JPG"),
    require("../assets/images/gallery/im2.JPG"),
    require("../assets/images/gallery/im3.JPG"),
    require("../assets/images/gallery/im4.JPG"),
    require("../assets/images/gallery/im5.JPG"),
    require("../assets/images/gallery/im7.PNG"),
    require("../assets/images/gallery/im9.JPG"),
    require("../assets/images/gallery/im10.JPG"),
    require("../assets/images/gallery/im11.JPG"),
    require("../assets/images/gallery/im12.JPG"),
    require("../assets/images/gallery/im13.JPG"),
    require("../assets/images/gallery/im14.JPG"),
    require("../assets/images/gallery/im15.JPG"),
    require("../assets/images/gallery/im16.JPG"),
    require("../assets/images/gallery/im17.JPG"),
    require("../assets/images/gallery/im18.JPG"),
    require("../assets/images/gallery/im19.JPG"),
    require("../assets/images/gallery/im20.JPG"),
  ],
}
,
{
  id:"2",

  title: "Kaudia Forest Kanatal Trek",
  subtitle: "A Tranquil Escape into Nature",
  duration: "2 Days",
  difficulty: "Easy",
  description: "A dense forest trek known for its rich biodiversity, deodar trees, and serene landscapes. Ideal for beginners and nature lovers.",
  maindescription: "The Kaudia Forest Kanatal Trek is a gentle yet mesmerizing walk through dense deodar forests, making it perfect for beginners and nature enthusiasts. This scenic trail is home to diverse flora and fauna, offering an immersive experience in the tranquility of nature. The trek provides breathtaking views of lush valleys and snow-clad peaks, creating an ideal retreat from city life. With its easy terrain and picturesque landscapes, this trek is an excellent choice for a relaxing yet adventurous getaway.",
  highestAltitude: "8,000 ft",
  mainbanner:require("../assets/images/bannertrek/Kaudia.jpeg"),

  totalDistance: "10 km",
  accommodation: "Tents",
  basecamp: "Kanatal",
  pickupPoint: "Dehradun Railway Station",
  trekFee: "₹4,000",
  image: require("../assets/images/Kaudia Forest Kanatal2.jpeg"),
  // gallery: [
  //   require("../assets/images/gallery/kaudia1.JPG"),
  //   require("../assets/images/gallery/kaudia2.JPG"),
  //   require("../assets/images/gallery/kaudia3.JPG"),
  //   require("../assets/images/gallery/kaudia4.JPG"),
  //   require("../assets/images/gallery/kaudia5.JPG"),
  //   require("../assets/images/gallery/kaudia6.JPG"),
  //   require("../assets/images/gallery/kaudia7.JPG"),
  //   require("../assets/images/gallery/kaudia8.JPG"),
  //   require("../assets/images/gallery/kaudia9.JPG"),
  //   require("../assets/images/gallery/kaudia10.JPG"),
  // ],
}
,
{
  id:"3",

  title: "Deoban Weekend Trek",
  subtitle: "A Scenic High-Altitude Adventure",
  duration: "2 Days",
  difficulty: "Moderate - Difficult",
  description: "A high-altitude trek offering breathtaking views of the Himalayan ranges and dense forests of Deodar trees.",
  maindescription: "The Deoban Weekend Trek is a thrilling adventure through dense deodar forests and high-altitude meadows. This trek offers spectacular views of more than 55 Himalayan peaks, making it a paradise for nature lovers and photographers. The serene yet challenging trails provide a perfect blend of adventure and tranquility. Trekking through the untouched wilderness and camping under the star-lit sky creates an unforgettable experience for trekkers. Suitable for those seeking both thrill and scenic beauty, Deoban is an excellent weekend getaway.",
  highestAltitude: "9,300 ft",
  mainbanner:require("../assets/images/bannertrek/deoban_banner.webp"),

  totalDistance: "12 km",
  accommodation: "Tents",
  basecamp: "Chakrata",
  pickupPoint: "Dehradun Railway Station",
  trekFee: "₹4,800",
  image: require("../assets/images/deoban_.webp"),
  // gallery: [
  //   require("../assets/images/gallery/deoban1.JPG"),
  //   require("../assets/images/gallery/deoban2.JPG"),
  //   require("../assets/images/gallery/deoban3.JPG"),
  //   require("../assets/images/gallery/deoban4.JPG"),
  //   require("../assets/images/gallery/deoban5.JPG"),
  //   require("../assets/images/gallery/deoban6.JPG"),
  //   require("../assets/images/gallery/deoban7.JPG"),
  //   require("../assets/images/gallery/deoban8.JPG"),
  //   require("../assets/images/gallery/deoban9.JPG"),
  //   require("../assets/images/gallery/deoban10.JPG"),
  // ],
}
,
{
  id:"4",

  title: "Nag Tibba Trek",
  subtitle: "A Perfect Beginner’s Himalayan Adventure",
  duration: "3 Days",
  difficulty: "Moderate",
  description: "One of the best beginner treks in Uttarakhand, offering stunning views of the Swargarohini and Bandarpoonch peaks.",
  maindescription: "Nag Tibba Trek is a beginner-friendly trek that takes you to the highest peak of the Nag Tibba range in the lower Himalayas. The trek offers stunning views of the Swargarohini, Bandarpoonch, Kala Nag, and Gangotri peaks. The route passes through dense oak and rhododendron forests, charming meadows, and picturesque campsites. The summit rewards trekkers with breathtaking panoramic views, making it a perfect adventure for nature lovers and first-time trekkers.",
  highestAltitude: "9,915 ft",
  mainbanner:require("../assets/images/bannertrek/naga.jpeg"),

  totalDistance: "16 km",
  accommodation: "Tents",
  basecamp: "Pantwari",
  pickupPoint: "Dehradun Railway Station",
  trekFee: "₹5,200",
  image: require("../assets/images/nag-tibba-temple.webp"),
  // gallery: [
  //   require("../assets/images/gallery/nagtibba1.JPG"),
  //   require("../assets/images/gallery/nagtibba2.JPG"),
  //   require("../assets/images/gallery/nagtibba3.JPG"),
  // ],
},
{
  id:"5",

  title: "Binsar Weekend Trek",
  subtitle: "A Tranquil Retreat in Binsar Wildlife Sanctuary",
  duration: "2 Days",
  difficulty: "Moderate",
  description: "A peaceful trek in the Binsar Wildlife Sanctuary, known for rich flora, birdwatching, and views of the Nanda Devi range.",
  maindescription: "The Binsar Weekend Trek is an ideal escape for nature lovers and birdwatchers. Located in the heart of the Binsar Wildlife Sanctuary, the trail passes through dense oak and rhododendron forests. It offers panoramic views of the Nanda Devi, Kedarnath, and Panchachuli peaks. With its serene environment, diverse wildlife, and breathtaking landscapes, Binsar is a perfect trek for a short yet rejuvenating adventure.",
  highestAltitude: "7,900 ft",
  mainbanner:require("../assets/images/bannertrek/binsar.webp"),

  totalDistance: "12 km",
  accommodation: "Tents",
  basecamp: "Binsar",
  pickupPoint: "Kathgodam Railway Station",
  trekFee: "₹4,500",
  image: require("../assets/images/binsar.jpeg"),
  // gallery: [
  //   require("../assets/images/gallery/binsar1.JPG"),
  //   require("../assets/images/gallery/binsar2.JPG"),
  // ],
},
{
  id:"6",

  title: "Chopta Chandrashila Trek",
  subtitle: "A Trek to the Tungnath Temple & Chandrashila Summit",
  duration: "2 Days",
  difficulty: "Moderate",
  description: "A popular trek leading to the Tungnath temple and Chandrashila summit, offering stunning 360° views of the Himalayas.",
  maindescription: "Chopta Chandrashila Trek is a breathtaking journey through dense forests and alpine meadows, leading to the highest Shiva temple, Tungnath. The summit of Chandrashila offers mesmerizing 360° views of the Himalayan giants like Nanda Devi, Trishul, and Chaukhamba. The trek is perfect for both beginners and seasoned trekkers seeking an adventure amidst stunning natural beauty.",
  highestAltitude: "13,000 ft",
  mainbanner:require("../assets/images/bannertrek/Chopta-Tungnath-Chandrashila-Trek4.jpg"),

  totalDistance: "10 km",
  accommodation: "Tents",
  basecamp: "Chopta",
  pickupPoint: "Rishikesh Railway Station",
  trekFee: "₹5,800",
  image: require("../assets/images/chpta.jpeg"),
  // gallery: [
  //   require("../assets/images/gallery/chopta1.JPG"),
  //   require("../assets/images/gallery/chopta2.JPG"),
  // ],
},
{
  id:"7",

  title: "Deoriatal Trek",
  subtitle: "A Serene Lake Trek with Himalayan Views",
  duration: "2 Days",
  difficulty: "Moderate",
  description: "A scenic trek leading to Deoriatal Lake, famous for its crystal-clear reflections of Chaukhamba peaks.",
  maindescription: "The Deoriatal Trek is a peaceful yet rewarding trek that leads to the stunning Deoriatal Lake. Surrounded by lush forests, the lake is famous for its mirror-like reflections of the Chaukhamba peaks. The trek offers a tranquil environment, abundant birdlife, and mesmerizing sunrise views, making it an ideal weekend adventure.",
  highestAltitude: "7,800 ft",
  mainbanner:require("../assets/images/bannertrek/deoriyatal-1155775.webp"),

  totalDistance: "8 km",
  accommodation: "Tents",
  basecamp: "Sari Village",
  pickupPoint: "Rishikesh Railway Station",
  trekFee: "₹4,600",
  image: require("../assets/images/deoria.png"),
  // gallery: [
  //   require("../assets/images/gallery/deoriatal1.JPG"),
  //   require("../assets/images/gallery/deoriatal2.JPG"),
  // ],
},
{
  id:"8",

  title: "Surkanda Devi Temple Trek",
  subtitle: "A Spiritual Journey with Panoramic Views",
  duration: "2 Days",
  difficulty: "Moderate",
  description: "A spiritual trek leading to the Surkanda Devi Temple, offering mesmerizing views of snow-capped peaks.",
  maindescription: "The Surkanda Devi Temple Trek is a short but steep hike to one of the most revered Shakti Peethas in Uttarakhand. The temple is perched on a hilltop, offering breathtaking views of the Himalayan ranges. The trek is especially beautiful during the Ganga Dussehra festival, attracting pilgrims and trekkers alike. The surrounding forests and meadows add to the scenic charm of this spiritual journey.",
  highestAltitude: "9,040 ft",
  mainbanner:require("../assets/images/bannertrek/Surkanda-Devi-Temple.jpg"),

  totalDistance: "5 km",
  accommodation: "Lodges/Tents",
  basecamp: "Kaddukhal",
  pickupPoint: "Dehradun Railway Station",
  trekFee: "₹3,500",
  image: require("../assets/images/shukra.jpeg"),
  // gallery: [
  //   require("../assets/images/gallery/surkanda1.JPG"),
  //   require("../assets/images/gallery/surkanda2.JPG"),
  // ],
},
{
  id:"9",

  title: "Bhadraj Temple Trek",
  subtitle: "A Scenic Trail to the Temple of Lord Bal Bhadra",
  duration: "2 Days",
  difficulty: "Moderate",
  description: "A lesser-known trek near Mussoorie, leading to the Bhadraj Temple, with panoramic views of the Doon Valley and Himalayas.",
  maindescription: "The Bhadraj Temple Trek is a peaceful yet scenic trail leading to the temple dedicated to Lord Bal Bhadra, brother of Lord Krishna. The trek offers stunning panoramic views of the Doon Valley, Chakrata ranges, and the snow-clad Himalayas. With its gentle terrain and breathtaking landscapes, it is an ideal trek for spiritual seekers and adventure lovers alike.",
  highestAltitude: "8,900 ft",
  totalDistance: "10 km",
  mainbanner:require("../assets/images/bannertrek/bhadraj.jpg"),

  accommodation: "Tents",
  basecamp: "Mussoorie",
  pickupPoint: "Dehradun Railway Station",
  trekFee: "₹4,200",
  image: require("../assets/images/bhadraj-temple-7903192.webp"),
  // gallery: [
  //   require("../assets/images/gallery/bhadraj1.JPG"),
  //   require("../assets/images/gallery/bhadraj2.JPG"),
  // ],
}

];



const TrekSlider = () => {
    const CustomPrevArrow = (props) => {
        const { onClick } = props;
        return <button className="custom-prev-arrow" onClick={onClick}><FontAwesomeIcon icon={faChevronLeft} /></button>;
      };
      
      // Custom Next Arrow
      const CustomNextArrow = (props) => {
        const { onClick } = props;
        return <button className="custom-next-arrow" onClick={onClick}><FontAwesomeIcon icon={faChevronRight} /></button>; // No text, just arrow
      };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,

    slidesToScroll: 3,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div className="container mx-auto ">
      <Slider {...settings}>
        {trekData.map((trek, index) => (
        //   <div key={index} className="p-2">
        //         <div className="card">
        //   <img src="https://picsum.photos/id/54/400/300" />
        //   <div className="card-body">
        //     <h2>{trek.title}</h2>
        //     <p>{trek.description}</p>
            
        //   </div>
        // </div>
        //   </div>
        <div key={index} className="p-2">

          <div class=" wow fadeInUp card" data-wow-delay="0.3s">
          <div class="room-item shadow rounded overflow-hidden">
              <div class="position-relative">
                  <img class="img-fluid w-100" src={trek.image} alt="" />
                  <small class="position-absolute start-0 top-100 translate-middle-y bg-primary  rounded py-1 px-3 ms-4 text-dark">{trek.duration}</small>
              </div>
              <div class="p-3 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                      <h5 class="mb-0">{trek.title}</h5>
                      <div class="ps-2">
                          <small class="fa fa-star text-primary"></small>
                          <small class="fa fa-star text-primary"></small>
                          <small class="fa fa-star text-primary"></small>
                          <small class="fa fa-star text-primary"></small>
                          <small class="fa fa-star text-primary"></small>
                      </div>
                  </div>
                  {/* <div class="d-flex mb-3">
                      <small class="border-end me-3 pe-3"><i class="fa fa-bed text-primary me-2"></i>3 Bed</small>
                      <small class="border-end me-3 pe-3"><i class="fa fa-bath text-primary me-2"></i>2 Bath</small>
                      <small><i class="fa fa-wifi text-primary me-2"></i>Wifi</small>
                  </div> */}
                  <p class="text-body mb-3">{trek.description}</p>                  <div class="d-flex justify-content-between">
                      {/* <Link to={"/"} class="btn btn-sm btn-primary  py-2 px-4 text-dark" href="">Get trek info</Link> */}
                      <Link 
                      // to={`/treks/${trek.title.toLowerCase().replace(/\s+/g, "-")}`}
                      to={{
                        pathname: `/treks/${trek.title.toLowerCase().replace(/\s+/g, "-")}`,
                      }}
                      state={{ trek }} 
                      className="btn btn-sm btn-primary py-2 px-4 text-dark">
  Get trek info
</Link>
                      <a class="btn btn-sm btn-dark  py-2 px-4" href="">View Dates</a>
                  </div>
              </div>
          </div>
      </div>
      </div>
        ))}
      </Slider>
    </div>
  );
};

export default TrekSlider;
