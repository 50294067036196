import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  return (
    <>
      {/* Contact Start */}
      <section className="main-contact">
        <div className="container-xxl py-5">
          <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="bredcumn-text text-center text-primary text-uppercase">
                Contact Us
              </h6>
              <h3 className="mb-5">
                <span className="text-primary text-uppercase">Contact</span> For Any Query
              </h3>
            </div>
            <div className="row g-4">
              <div className="col-12">
                <div className="row gy-4">
                  <div className="col-md-4">
                    <h6 className="bredcumn-text1 text-start text-primary text-uppercase">
                      Booking
                    </h6>
                    <p>
                      <FontAwesomeIcon icon={faEnvelope} className="text-primary me-2" />
                      bornfreetreks@gmail.com
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h6 className="bredcumn-text1 text-start text-primary text-uppercase">
                      Phone
                    </h6>
                    <p>
                      <FontAwesomeIcon icon={faPhoneAlt} className="text-primary me-2" />
                      +91 63973 50544
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h6 className="bredcumn-text1 text-start text-primary text-uppercase">
                      Address
                    </h6>
                    <p>
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary me-2" />
                      22A, GMS Road, Engineers Enclave, Kanwali, Dehradun, Uttarakhand – 248001, India
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
              <iframe
                className="position-relative rounded w-100 h-100"
               src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d13238.095828092255!2d78.00193620427116!3d30.320883646565193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s22a%2C%20GMS%20Rd!5e1!3m2!1sen!2sin!4v1740116269247!5m2!1sen!2sin"
                  style={{ minHeight: "350px", border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
              <div className="col-md-6">
                <div className="wow fadeInUp" data-wow-delay="0.2s">
                  <form>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Your Name"
                          />
                          <label htmlFor="name">Your Name</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Your Email"
                          />
                          <label htmlFor="email">Your Email</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="subject"
                            placeholder="Subject"
                          />
                          <label htmlFor="subject">Subject</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <textarea
                            className="form-control"
                            placeholder="Leave a message here"
                            id="message"
                            style={{ height: "150px" }}
                          ></textarea>
                          <label htmlFor="message">Message</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-primary w-100 py-3 text-dark" type="submit">
                          SEND MESSAGE
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact End */}
    </>
  );
};

export default Contact;
