import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
              
import Home from "./Screen/home";
import Header from "./component/header";
import Contact from "./Screen/contact";
import TrekPage from "./Screen/Detailspage";
import Footer from "./component/footer";
import Faq from "./Screen/Faq";
import Mainheader from "./component/mainheader";
import Services from "./Screen/Services";
import About from "./Screen/About";

function App() {
  return (
    <Router>
      {/* <Header /> */}
      <Mainheader />
      <Routes>
        <Route path="/" element={<Home />} />
               <Route path="/contact-us" element={<Contact />} />
               <Route path="/treks/:trekName" element={<TrekPage />} />
               <Route path="/faqs" element={<Faq />} />
               <Route path="/services" element={<Services />} />
               <Route path="/about-us" element={<About />} />


      </Routes>
    <Footer/>
    </Router>
  );
}

export default App;
