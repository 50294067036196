const items = [
  {
    title: "Trek Again Assurance",
    value: "trek_again",
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 10L12 3L21 10V21H15V14H9V21H3V10Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    description: "If you can't finish a trek or love it too much, you can redo it with us for free. No extra cost involved."
  },
  {
    title: "Connect with Fellow Trekkers",
    value: "like_minded",
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="7" r="4" stroke="black" stroke-width="2"/>
    </svg>`,
    description: "Experience trekking with a community that values fitness, mindfulness, and a deep respect for nature."
  },
  {
    title: "Pre-Trek Expert Support",
    value: "expert_guidance",
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="black" stroke-width="2"/>
    </svg>`,
    description: "Get step-by-step assistance from our expert coordinators, from registration to the trek itself."
  },
  {
    title: "Leave No Trace, Give Back",
    value: "clean_mountains",
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.4 15.6L22 12L19.4 8.4L15.6 6.6L12 4L8.4 6.6L4.6 8.4L2 12L4.6 15.6L8.4 17.4L12 20L15.6 17.4L19.4 15.6Z" stroke="black" stroke-width="2"/>
    </svg>`,
    description: "We don't just avoid littering; we clean up the mountains we trek, ensuring a positive impact on nature."
  },
  {
    title: "Women-Friendly Trekking Groups",
    value: "women_friendly",
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 8C18 5.2 15.8 3 13 3H11C8.2 3 6 5.2 6 8V12C6 12.8 5.7 13.5 5.2 14L4 15.5V17H20V15.5L18.8 14C18.3 13.5 18 12.8 18 12V8Z" stroke="black" stroke-width="2"/>
    </svg>`,
    description: "With 30% of our trekkers being women, every group is designed to be inclusive and comfortable for solo women travelers."
  },
  {
    title: "Top-Quality Trekking Gear",
    value: "best_equipment",
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 21H14" stroke="black" stroke-width="2"/>
    </svg>`,
    description: "Benefit from decades of expertise with premium-quality trekking equipment provided for your adventure."
  }
];

export default items;
