import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPaw,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faInfoCircle,
  faHiking,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    
<section className="bg-blue main-footer">
    <div className="container-fluid">
      <footer className="text-center text-lg-start text-white">
        <div className="container p-4">
          <div className="row my-4">
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <div
                className=" shadow d-flex align-items-center justify-content-center mb-4 mx-auto"
                style={{ width: "150px", height: "150px" }}
              >
                <img
                  src={require("../assets/images/bornfree.png")}
                  height="170"
                  className="rounded"
                  alt="Bornfree Treks Logo"
                  loading="lazy"
                />
              </div>
              <p className="text-center">
                Explore the world with Bornfree Treks – Your adventure partner!
              </p>
              <ul className="list-unstyled d-flex flex-row justify-content-center">
                <li>
                  <a className="text-white px-2" href="#">
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </a>
                </li>
                <li>
                  <a className="text-white px-2" href="#">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
                <li>
                  <a className="text-white ps-2" href="#">
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase mb-4 text-primary">Treks & Tours</h5>
              <ul className="list-unstyled">
                {["Upcoming Treks", "Customized Tours", "Travel Guides", "Popular Destinations", "Gear & Equipment", "Travel Tips", "Volunteer Opportunities"].map((text, index) => (
                  <li key={index} className="mb-2">
                    <a href="#" className="text-white">
                      <FontAwesomeIcon icon={faHiking} className="pe-3" />
                      {text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase mb-4 text-primary">Information</h5>
              <ul className="list-unstyled">
                {["About Us", "Our Team", "Travel Policies", "FAQs", "Careers", "Contact Us"].map((text, index) => (
                  <li key={index} className="mb-2">
                    <a href="#" className="text-white">
                      <FontAwesomeIcon icon={faInfoCircle} className="pe-3" />
                      {text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase mb-4 text-primary">Contact</h5>
              <ul className="list-unstyled">
                <li>
                  <p>
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="pe-2" />
                    22A, GMS Road, Engineers Enclave, Kanwali, Dehradun, Uttarakhand – 248001, India
                  </p>
                </li>
                <li>
                  <p>
                    <FontAwesomeIcon icon={faPhone} className="pe-2" /> +91 63973 50544
                   
                  </p>
                </li>
                <li>
                  <p>
                    <FontAwesomeIcon icon={faEnvelope} className="pe-2" />
                    bornfreetreks@gmail.com
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
          © 2025 Copyright:
          <a className="text-white" href="https://bornfreetreks.com">
            BornfreeTreks.com
          </a>
        </div>
      </footer>
    </div>
</section>

  );
};

export default Footer;
