import React from 'react';
import Mainslider from '../component/Mainslider';
import '../assets/css/style.css';
import '../assets/css/comman.css'
import TrekSlider from '../component/Cards';
import FAQ from '../component/Faq';
import items from '../service/cardAdvantage'
import trekData from '../service/trekdata'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faHandsHelping, faUsers, faWalking } from '@fortawesome/free-solid-svg-icons';



const Home = () => {
    
  const stories = [
    {
      id: 1,
      title: "Finding Inner Peace in the Himalayas",
      content: "Trekking in the Himalayas helped me disconnect from the digital world and rediscover myself.",
      image: require("../assets/images/gallery/IMG_9253.JPG")
    },
    {
      id: 2,
      title: "Overcoming Fears on the Everest Trail",
      content: "I conquered my fear of heights while trekking to Everest Base Camp. It was a life-changing experience.",
      image: require("../assets/images/gallery/IMG_9254.JPG")
    },
    {
      id: 3,
      title: "Building Lifelong Friendships in the Andes",
      content: "Trekking in Peru introduced me to amazing people, and we bonded over our shared challenges and victories.",
      image: require("../assets/images/gallery/IMG_9541.JPG")
    }
  ];
  
    
    return (
        <div>
<Mainslider />
{/* about */}
<section className='about-main'>
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6">
            <h6 className="section-title text-start text-primary text-uppercase">About Us</h6>
            <h3 className="mb-4">
              Welcome to <span className="text-primary text-uppercase">BORNFREETREKS</span>
            </h3>
            <p className="mb-4">
            At Born Free Treks, we know how important it is to address common queries and concerns before you plan your trekking adventure. Here are answers to some of the most frequently Googled questions about trekking:
            </p>
            <div className="row g-3 pb-4">
            {[
          { icon: faWalking, label: "Treks",list:"50", },
          { icon: faHandsHelping, label: "Support Team",list:"80", },
          { icon: faUsers, label: "Clients",list:"350", },
        ].map((item, index) => (
          <div key={index} className="col-sm-4 wow fadeIn" data-wow-delay={`${0.1 + index * 0.2}s`}>
            <div className="border rounded p-1">
              <div className="border rounded text-center p-4">
                <FontAwesomeIcon icon={item.icon} className="fa-2x text-primary mb-2" />
                <h2 className="mb-1" data-toggle="counter-up">{item.list}</h2>
                <p className="mb-0">{item.label}</p>
              </div>
            </div>
          </div>
        ))}
            </div>
            <a className="btn btn-primary py-3 px-5 mt-2 text-dark rounded-2 fw-bold" href="#">Explore More</a>
          </div>
          <div className="col-lg-6">
            <div className="row g-3">
              {[
                { src: require("../assets/images/gallery/im4.JPG"), width: "75%", align: "text-end", marginTop: "25%" },
                { src: require("../assets/images/gallery/im5.JPG"), width: "90%", align: "text-start",marginBottom:"5%" },
                { src: require("../assets/images/gallery/im9.JPG"), width: "50%", align: "text-end" },
                { src: require("../assets/images/gallery/im8.JPG"), width: "75%", align: "text-start",marginTop:"5%" },
              ].map((img, index) => (
                <div key={index} className={`col-6 ${img.align}`} style={{ marginTop: img.marginTop || 0 }}>
                  <img
                    className="img-fluid rounded wow zoomIn"
                    data-wow-delay={`${0.1 + index * 0.2}s`}
                    src={img.src}
                    style={{ width: img.width }}
                    alt="About Us"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    {/* top picks */}
           <section className='sec-1 space'>

           <div className='container'>
            <div className='mb-3 border_head_bottom'>
            <div className='row'>
<div className='col-sm-6'>
    <h3>Our Founder’s 7 Favorite Choices for 2025</h3>

</div>
<div className='col-sm-6'>
    <h5>Our founder seldom favors treks, but here’s his exclusive selection—each offering a taste of diverse regions across the country for a truly enriching trekking experience. Mark these for the year, and your 2025 trekking plan is set!</h5>

</div> 

            </div>
            </div>
            <div className='trip-main'>
            <TrekSlider />
            </div>
            
            </div>
            
    </section>
    <section className='sec-2 space'>
        <div className='container'>
            <h3 className='mb-3 border_head_bottom'>How trekking with BornFree Treks transforms your journey</h3>
            <div className='row'>
                <div className='col-sm-6'>
                <div className="youtube-container">
      <div>
        
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/dQw4w9WgXcQ"  // Replace with your desired YouTube link
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>

                </div>
                <div className='col-sm-6'>
                <div class="p-text-2 fl-style">
  <p><span>A</span>t BornFree Treks, a trek is more than just an adventure—it’s a transformative experience. We go the extra mile to create journeys that deeply impact your mind, body, and spirit.</p>
  <p>Our approach is shaped by years of expertise, ensuring every trek is thoughtfully designed. Our experienced trek leaders integrate mindfulness into your journey, guiding you through reflective exercises in the heart of the mountains. More often than not, the person you are before and after a trek is remarkably different.</p>
  <p>This is what makes trekkers develop a deep appreciation for the outdoors and continue their trekking adventures long after they return home.</p>
</div>

</div>

            </div>

        </div>
        </section>

        <section className='sec-3 space'>
            <div className='container'>
            <h3 className='mb-3 border_head_bottom'>Frequenty Asked Questions</h3>
            <FAQ />
            </div>
            </section>
            <section class="cta-section">
  <div class="cta-overlay">
    <div class="cta-content">
      <h1 class="cta-heading">Planning a trek for 2025?</h1>
      <h2 class="cta-subheading">Talk to Our Experts First</h2>
      <p class="cta-description">Our Trek Advisors have helped over 2,500 trekkers plan treks in 2024. They're here to help you start 2025 with the right trek.</p>
      <a href="/opt-in/talk-to-indiahikes-trek-advisors">
        <button class="cta-button">Schedule a call now</button>
      </a>
    </div>
  </div>
</section>

    <section className='sec-4 space'>
        <div className='container'>
            <h3 className='mb-3 border_head_bottom'>The Bornfreetreks Advantage</h3>

        <div className='new_card_con row'>
           
                {
                    items.map((item,i)=>{
return(

    <div className='card-advantage'>
        <div className='d-flex justify-content-between'>
        <h6>{item.title}</h6>
        <div dangerouslySetInnerHTML={{ __html: item.icon }} />
    

        </div>
        <p>{item.description}</p>
        
        </div>
)

                    }

                    )
                }

         

        </div>

        </div>
    </section>
    {/* Trekkers share stories of how trekking transformed their lives */}
    <section className='sec-5 space'>

        <div className='container'>
            <h3 className='mb-3 border_head_bottom'>Trekkers share stories of how trekking transformed their lives</h3>
      <div className="row">
        {stories.map((story) => (
          <div key={story.id} className="col-md-4 mb-4">
            <div className="card h-100">
              <img src={story.image} style={{maxHeight:"29vh",objectFit:"cover"}} className="card-img-top" alt={story.title} />
              <div className="card-body">
                <h5 className="card-title">{story.title}</h5>
                <p className="card-text">{story.content}</p>
              </div>
            </div>
          </div>
        ))}
    </div>
                    </div>

    </section>
    <section className='sec-6 space'>
    <div className="container-fluid">
      <h2 className="trek-heading">Treks by Categories</h2>
      <div className="row">
        {trekData.map((category, index) => (
          <div className='col-sm-2'>
          <div className="trek-card1 " key={index}>
            <h3 className="trek-title">
              <span className="trek-icon">{category.icon}</span> {category.title}
            </h3>
            <ul className="trek-list">
              {category.items.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          </div>
          </div>
        ))}
      </div>
    </div>
    </section>

        </div>
    );
}

export default Home;
