import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhoneAlt, faEnvelopeOpen, faBars } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebookF, faLinkedinIn, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

const Mainheader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {/* Topbar */}
      <div className="container-fluid bg-blue px-5 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div className="d-inline-flex align-items-center" style={{ height: "45px" }}>
              <small className="me-3 text-light">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" /> 22A, GMS Road, Engineers Enclave, Kanwali, Dehradun, Uttarakhand – 248001, India
              </small>
              <small className="me-3 text-light">
                <FontAwesomeIcon icon={faPhoneAlt} className="me-2" />+91 63973 50544
              </small>
              <small className="text-light">
                <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> bornfreetreks@gmail.com
              </small>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div className="d-inline-flex align-items-center" style={{ height: "45px" }}>
              <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="#"><FontAwesomeIcon icon={faTwitter} /></a>
              <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
              <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
              <a className="btn btn-sm btn-outline-light btn-sm-square rounded me-2" href="#"><FontAwesomeIcon icon={faInstagram} /></a>
              <a className="btn btn-sm btn-outline-light rounded btn-sm-square" href="#"><FontAwesomeIcon icon={faYoutube} /></a>
            </div>
          </div>
        </div>
      </div>

      {/* Navbar */}
      <div className="container-fluid position-relative p-0 shadow">
        <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          <a href="/" className="navbar-brand p-0">
            <h1 className="text-primary m-0">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="me-3 text-blue" />Born<span className="text-blue">free</span>treks
            </h1>
          </a>
          <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
            <FontAwesomeIcon icon={faBars} />
          </button>
          <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`} id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <a href="/" className="nav-item nav-link active">Home</a>
              <Link to={"./about-us"} className="nav-item nav-link">About</Link>
              <Link to={'./services'} className="nav-item nav-link">Services</Link>
              <Link to={'./faqs'} className="nav-item nav-link">FAQ</Link>

              {/* <a href="package.html" className="nav-item nav-link">Packages</a> */}
             
              <Link to={'./contact-us'} className="nav-item nav-link">Contact</Link>
            </div>
            
            <Link to={'./contact-us'} className="btn btn-primary text-dark rounded-pill py-2 px-4">Register</Link>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Mainheader;
