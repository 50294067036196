const trekData = [
    {
      title: "Treks by Season",
      icon: "☀️",
      items: ["Spring", "Summer", "Monsoon", "Autumn", "Winter"],
    },
    {
      title: "Treks by Month",
      icon: "📆",
      items: [
        "January", "February", "March", "April", "May", "June", "July", "August",
        "September", "October", "November", "December",
      ],
    },
    {
      title: "Treks by Duration",
      icon: "⏳",
      items: ["2 days", "3 days", "4 days", "5 days", "6 days", "7+ days"],
    },
    {
      title: "Treks by Difficulty",
      icon: "⚡",
      items: ["Easy", "Easy - Moderate", "Moderate", "Moderate - Difficult", "Difficult"],
    },
    {
      title: "Treks by Region",
      icon: "📍",
      items: [
        "Uttarakhand", "Himachal Pradesh", "Lahaul and Spiti", "Jammu & Kashmir",
       
      ],
    },
    {
      title: "Treks by Experience",
      icon: "🔭",
      items: [
        "Family Treks", "Stargazing Treks", "Senior Treks", 
        "Adventure Therapy", "Summer Camps", "Youth Camps",
      ],
    },
  ];
  export default trekData;
