import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import FAQ from "../component/Faq";

const Faq = () => {
  return (
    <section className="space"> 
        <div className="container">
    <FAQ />
    </div>
    </section>
    
  );
};

export default Faq;
