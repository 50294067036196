import React, { useState } from "react";

const faqs = [
  "Why is your trek fee higher than other trekking companies?",
  "I'm a solo woman. Is it safe for me?",
  "What are some easy treks for beginners?",
  "Why are you so strict about fitness approvals?",
  "I have health issues like BP, asthma, diabetes. Can I trek?",
  "Can I join your groups as a solo trekker?",
  "Why do I have to register so much in advance?",
  "I'm obese and my BMI is high. Can I trek with you?",
  "Do you have any group discounts?",
  "What are toilets like? I'm worried!"
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container ">
      <div className="faq-grid">
        {faqs.map((question, index) => (
          <div key={index} className="faq-item" onClick={() => toggleFAQ(index)}>
            <div className="faq-question">
              {question}
              <span className="faq-icon">{openIndex === index ? "-" : "+"}</span>
            </div>
            {openIndex === index && <div className="faq-answer">Answer for: {question}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
