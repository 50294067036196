import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import PhotoGallery from "../component/Gallery";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointer, faUsers, faStar, faLifeRing, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";


const TrekPage = () => {
  const [showMore, setShowMore] = useState(false);  
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const location = useLocation();
  const { trek } = location.state || {}; // Extract trek details
console.log(trek,'trek detao;s')
  if (!trek) {
    return <h2>No Trek Details Found</h2>;
  }


  const handleDownload = () => {
    const pdfUrl = "/kedarkantha.pdf"; // Path to the PDF file
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "kedarkantha.pdf"; // Name for the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  return (
    <main>
     <section className="cta-section" style={{ backgroundImage: `url(${trek.mainbanner})`,height:"45vh" }}>
  <div className="cta-overlay">
    <div className="cta-content">
      <h1 className="cta-heading">{trek.title}</h1>
      <p className="cta-description">{trek.description}</p>
    </div>
  </div>
</section>

        <section>
        <div className="container mt-4">
      {/* Gallery Section */}
      
<div className="row">
  <div className="col-sm-8">
  <div className="trek-details  bg-white  rounded mt-4">
        {/* <p className="text-muted">
         {trek.description}
        </p> */}

        {/* Trek Information */}
        <div className="row">
          <div className="col-md-6">
            <p>🌄 <strong>Trek Difficulty:</strong>{trek.difficulty}</p>
            <p>⏳ <strong>Duration:</strong> {trek.duration}</p>
            <p>⛰️ <strong>Highest Altitude:</strong> {trek.highestAltitude}</p>
            <p>📏 <strong>Total Distance:</strong> {trek.totalDistance}</p>
          </div>
          <div className="col-md-6">
            <p>📍 <strong>Basecamp:</strong> {trek.basecamp}</p>
            <p>⛺ <strong>Accommodation:</strong> {trek.accommodation}</p>
            <p>📌 <strong>Pickup Point:</strong> <a href="#" className="text-decoration-none">{trek.pickupPoint}</a></p>
          </div>
        </div>
        <h3 className="text-dark border_head_bottom">{trek.subtitle}</h3>
        <p>     
              {trek.maindescription}

        </p>


        {/* Pricing Section */}
       

        {/* Buttons */}
        <div className="d-flex justify-content-between mt-3">
          <a href="#" className="btn btn-primary">Talk to our Trek Advisors</a>
          <a href="#" className="btn btn-warning">FAQs</a>
        </div>
      </div>

  </div>
      {/* Trek Details Section */}
     
      <div className="col-sm-4">
         <div className="price-box pricebox text-center mt-4 mb-3 p-3">
          <h2 className="text-dark fs-normal" style={{fontSize:"12px"}}>PRICE</h2>
          <p className="price"> {trek.trekFee}</p>
          <p className="text-muted">+ 5% GST | + ₹180 Trek Insurance</p>
        </div>

        <div className="tour-booking-widget traveltour-sidebar-area">
      
      <div className="widget traveltour-widget">
        <div className="textwidget">
          <div style={{ marginTop: "-10px" }}></div>
          <div style={{ color: "#ffffff", padding: "50px 40px 30px 40px" }}>
            <h3 style={{ color: "#ffffff" }}>Got a Question?</h3>
            <div>
              <p>Do not hesitate to give us a call. Our team of experts will be happy to talk to you.</p>
              <p>
                <FontAwesomeIcon icon={faPhone} style={{ fontSize: 20, color: "#32AFA6", marginRight: 10 }} />
                <span style={{ fontSize: 20, color: "#ffffff", fontWeight: 600 }}>+91 63973 50544
                </span>
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: 17, color: "#32AFA6", marginRight: 10 }} />
                <span style={{ fontSize: 14, color: "#fff", fontWeight: 600 }}>bornfreetreks@gmail.com</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
      </div>
    </div>

        </section>
        <section className="gallery-sec">
          <PhotoGallery />

        </section>
        <section>
        <div className="container mt-5">
        <button onClick={handleDownload}>Download PDF</button>;
     
      <h3 className="fw-bold">What I Like About the Ali Bedni Bugyal Trek</h3>
      <div className="row mt-4">
        <div className="col-md-8">
          <h5 className="fw-bold">1. Grand Mountain Views From The Most Beautiful Meadows Of Our Country</h5>
          <p>
            You see Mt Trishul and Mt Nanda Ghunti in their grandest of views. They are jutting out. By jutting out I mean you see their full face, like the entire 6,000-7,000 feet of Mt Trishul right in front of you. Imagine the grandiosity with the sun setting on it.
          </p>
          <p>
            You haven’t seen such grand views on any trek. Forget about any meadow trek.
          </p>
          <p>
            To add to it, you are at around 11,500 feet with the evening rays on Mt Trishul, especially from Bedni Bugyal. It is a powerful sight. I have seen trekkers standing in these meadows with tears in their eyes.
          </p>
          <p>And I want you to watch this.</p>
          {showMore && (
            <>
              <h5 className="fw-bold">2. Beautiful Flora and Fauna</h5>
              <p>
                The trek is filled with rich biodiversity, featuring a variety of flowers and wildlife unique to this region.
              </p>
              <h5 className="fw-bold">3. The Mesmerizing Sunrises</h5>
              <p>
                Watching the sunrise from the top of Ali Bedni Bugyal is an experience like no other. The sky paints itself with mesmerizing hues of orange and pink.
              </p>
            </>
          )}
          <button className="btn btn-primary mt-3" onClick={() => setShowMore(!showMore)}>
            {showMore ? "Read Less" : "Read More"}
          </button>
        </div>
        <div className="col-md-4 text-center main-image-details">
          <img
            src={require("../assets/images/gallery/IMG_9596.JPG")}
            className="img-fluid rounded"
            alt="Mountain View"
          />
          <p className="fst-italic text-muted mt-2">
            The views of Mt Trishul and Mt Nanda Ghunti on the Ali Bedni Bugyal trek are incomparable to any other trek. Picture by Dhaval Jajal.
          </p>
        </div>
      </div>
      {/* <div className="d-flex align-items-center mt-5 p-3 border rounded">
        <img
          src="https://www.indiahikes.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Farjun-majumdar.abc123.jpg&w=128&q=75"
          className="rounded-circle me-3"
          alt="Arjun Majumdar"
          width="60"
          height="60"
        />
        <div>
          <h6 className="fw-bold mb-0">Arjun Majumdar, Founder, CEO</h6>
          <p className="text-muted fst-italic">
            Here is Arjun Majumdar, Founder and CEO of Indiahikes talking about Ali Bedni Bugyal. Arjun is an entrepreneur by profession and a trekker by passion. Arjun started Indiahikes in 2008 with a vision to explore and document new trails, solve problems in the mountains and implement sustainable ways of trekking.
          </p>
        </div>
      </div> */}
    </div>
        </section>
    </main>
  );
};

export default TrekPage;
