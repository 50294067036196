import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import images from '../service/Galleryarray'

const PhotoGallery = () => {
  const [activeTab, setActiveTab] = useState("autumn");


  return (
    <div className="container mt-4">
      <h2 className="fw-bold">Photo Gallery</h2>
      <hr />
      <div className="btn-group mb-3" role="group">
        <button
          className={`btn ${activeTab === "autumn" ? "btn-warning" : "btn-light"}`}
          onClick={() => setActiveTab("autumn")}
        >
          Autumn (Mid-Sep to End-Nov)
        </button>
        <button
          className={`btn ${activeTab === "summer" ? "btn-warning" : "btn-light"}`}
          onClick={() => setActiveTab("summer")}
        >
          Summer (May to June)
        </button>
        <button
          className={`btn ${activeTab === "spring" ? "btn-warning" : "btn-light"}`}
          onClick={() => setActiveTab("spring")}
        >
          Spring (Mar & Apr)
        </button>
      </div>
      <div className="row">
        {images[activeTab].map((img, index) => (
          <div key={index} className="col-md-2 mb-3">
            <img src={img} className="img-fluid rounded" alt="Gallery" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoGallery;
