import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMountain, faHotel, faMapMarkedAlt, faCalendarCheck } from "@fortawesome/free-solid-svg-icons";

const services = [
  { icon: faMountain, title: "Trekking Adventures", delay: "0.1s" },
  { icon: faHotel, title: "Accommodation", delay: "0.3s" },
  { icon: faMapMarkedAlt, title: "Guided Tours", delay: "0.5s" },
  { icon: faCalendarCheck, title: "Event Planning", delay: "0.7s" },
  { icon: faMountain, title: "Trekking Adventures", delay: "0.1s" },
  { icon: faHotel, title: "Accommodation", delay: "0.3s" },
  { icon: faMapMarkedAlt, title: "Guided Tours", delay: "0.5s" },
  { icon: faCalendarCheck, title: "Event Planning", delay: "0.7s" },
];

const Services = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          {/* <h6 className="section-title bg-white text-center text-primary px-3">Services</h6> */}
          <h3 className="mb-5"> Services</h3>
        </div>
        <div className="row g-4">
          {services.map((service, index) => (
            <div
              key={index}
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay={service.delay}
            >
              <div className="service-item rounded pt-3">
                <div className="p-4">
                  <FontAwesomeIcon icon={service.icon} className="fa-3x text-primary mb-4" />
                  <h5>{service.title}</h5>
                  <p>
                    Experience the thrill of trekking, guided tours, comfortable stays, and seamless event planning in Uttarakhand.
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
