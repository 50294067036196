
const images = {
    autumn: [
      require("../assets/images/gallery/im1.JPG"),
      require("../assets/images/gallery/im2.JPG"),
      require("../assets/images/gallery/im3.JPG"),
      require("../assets/images/gallery/im4.JPG"),
      require("../assets/images/gallery/im5.JPG"),
      require("../assets/images/gallery/im7.PNG"),
      require("../assets/images/gallery/im9.JPG"),
      require("../assets/images/gallery/im10.JPG"),
      require("../assets/images/gallery/im11.JPG"),
      require("../assets/images/gallery/im12.JPG"),
      require("../assets/images/gallery/im13.JPG"),
      require("../assets/images/gallery/im14.JPG"),
      require("../assets/images/gallery/im15.JPG"),
      require("../assets/images/gallery/im16.JPG"),
      require("../assets/images/gallery/im17.JPG"),
      require("../assets/images/gallery/im18.JPG"),
      require("../assets/images/gallery/im19.JPG"),
      require("../assets/images/gallery/im20.JPG"),

    ],
    summer:[
        require("../assets/images/gallery/im4.JPG"),
        require("../assets/images/about-1.jpg"),
        require("../assets/images/about-1.jpg"),
        require("../assets/images/about-1.jpg"),
        require("../assets/images/about-1.jpg"),
        require("../assets/images/about-1.jpg"),
      ],
    spring: [
        require("../assets/images/about-1.jpg"),
        require("../assets/images/about-1.jpg"),
        require("../assets/images/about-1.jpg"),
        require("../assets/images/about-1.jpg"),
        require("../assets/images/about-1.jpg"),
        require("../assets/images/about-1.jpg"),
      ],
  };
  export default images